// import splayer.css
import './splayer.css'
import React from "react";
import PropTypes from "prop-types";
import ReactSplayerEvents from "./ReactSplayerEvents";


class ReactSplayer extends React.Component {

    controlsTimeout = null;
    settingsShown = false;
    isSeries = false;
    platform;
    playing = false;
    currentTime = 0;
    duration = null;
    play;
    speeds = [
        {label: "0.25", value: 0.25},
        {label: "0.5", value: 0.5},
        {label: "0.75", value: 0.75},
        {label: "Normal", value: 1},
        {label: "1.25", value: 1.25},
        {label: "1.5", value: 1.5},
        {label: "1.75", value: 1.75},
        {label: "2", value: 2}
    ];
    currentSpeed = this.speeds[3];

    // currentQuality;

    constructor(props) {
        super(props);

        this.eventHandler = new ReactSplayerEvents(this);

        this.wrapper = React.createRef();
        this.video = React.createRef();
        this.controls = React.createRef();
        this.seekbar = React.createRef();
        this.thumb = React.createRef();
        this.playingProgress = React.createRef();
        this.bufferedProgress = React.createRef();
        this.seekbarTimeTooltip = React.createRef();
        this.playPauseButton = React.createRef();
        this.forwardButton = React.createRef();
        this.rewindButton = React.createRef();
        this.volumeButton = React.createRef();
        this.timeIndicator = React.createRef();
        this.title = React.createRef();
        this.settingsButton = React.createRef();
        this.fullscreenButton = React.createRef();

        this.durationEl = React.createRef();
        this.currentTimeEl = React.createRef();
        this.spinnerEl = React.createRef();
        this.rewinded = React.createRef();
        this.forwarded = React.createRef();
        this.volumeIndicator = React.createRef();
        this.playIndicator = React.createRef();

        this.settings = React.createRef();
        this.settingsBody = React.createRef();
        this.settings_mainSection = React.createRef();
        this.settings_speedSection = React.createRef();
        this.settings_qualitySection = React.createRef();

        this.backButton = React.createRef();

        const storedVolume = localStorage.getItem("splayer-volume");

        this.mobilePlay = React.createRef();
        this.mobileRewind = React.createRef();
        this.mobileForward = React.createRef();

        this.state = {
            volume: storedVolume ? parseFloat(storedVolume) : 1,
            canplay: false,
            currentQuality: props.sources[0],
        }
        /* #### Video events ####*/
        this.onLoadedMetaData = this.eventHandler.onLoadedMetaData.bind(this);
        this.onDurationChange = this.eventHandler.onDurationChange.bind(this);
        this.onSuspend = this.eventHandler.onSuspend.bind(this);
        this.onStalled = this.eventHandler.onStalled.bind(this);
        this.onError = this.eventHandler.onError.bind(this);
        this.mouseMoveOnVideo = this.eventHandler.mouseMoveOnVideo.bind(this);
        this.onClickOnVideo = this.eventHandler.onClickOnVideo.bind(this);
        this.onVideoCanPlay = this.eventHandler.onVideoCanPlay.bind(this);
        this.onVideoCanPlayThrough = this.eventHandler.onVideoCanPlayThrough.bind(this);
        this.onVideoTimeUpdate = this.eventHandler.onVideoTimeUpdate.bind(this);
        this.onVideoProgress = this.eventHandler.onVideoProgress.bind(this);
        this.onVideoPlay = this.eventHandler.onVideoPlay.bind(this);
        this.onVideoPause = this.eventHandler.onVideoPause.bind(this);
        this.onVideoWaiting = this.eventHandler.onVideoWaiting.bind(this);
        this.onVideoLoadStart = this.eventHandler.onVideoLoadStart.bind(this);
        this.onVideoPlaying = this.eventHandler.onVideoPlaying.bind(this);
        this.onVolumechange = this.eventHandler.onVolumechange.bind(this);

        this.shortcutEvents = this.eventHandler.shortcutEvents.bind(this);

        this.onClickSectionHeader = this.eventHandler.onClickSectionHeader.bind(this);

        /* #### Control events ####*/
        this.mouseEntersControls = this.eventHandler.mouseEntersControls.bind(this);
        this.mouseLeavesControls = this.eventHandler.mouseLeavesControls.bind(this);
        this.clickOnPlayPause = this.eventHandler.clickOnPlayPause.bind(this);
        this.clickOnRewindButton = this.eventHandler.clickOnRewindButton.bind(this);
        this.clickOnForwardButton = this.eventHandler.clickOnForwardButton.bind(this);
        this.clickOnFullscreenButton = this.eventHandler.clickOnFullscreenButton.bind(this);
        this.onClickOnVolumeButton = this.eventHandler.onClickOnVolumeButton.bind(this);
        this.onClickCogButton = this.eventHandler.onClickCogButton.bind(this);
        this.onClickOutsideSettings = this.eventHandler.onClickOutsideSettings.bind(this);

        /* #### Seekbar events ####*/
        this.fullscreenChange = this.eventHandler.fullscreenChange.bind(this);
        this.onMouseEnterSeekbar = this.eventHandler.onMouseEnterSeekbar.bind(this);
        this.onMouseDownSeekbar = this.eventHandler.onMouseDownSeekbar.bind(this);
        this.onMouseMoveSeekbar = this.eventHandler.onMouseMoveSeekbar.bind(this);
        this.onMouseLeaveSeekbar = this.eventHandler.onMouseLeaveSeekbar.bind(this);
        this.handleDragThumb = this.eventHandler.handleDragThumb.bind(this);
        this.handleSeekbarRelease = this.eventHandler.handleSeekbarRelease.bind(this);
        this.onClickOnEpisodes = this.eventHandler.onClickOnEpisodes.bind(this);
        this.onClickNextEpisode = this.eventHandler.onClickNextEpisode.bind(this);
    }

    componentDidMount() {
        // console.clear();
        this._videoEvents();
        this._controlsEvents();
        this._shortcutEvents();
        this._seekbarEvents();
        this.eventHandler._settingsInitStyles();
        this._settingsEvents();
        this.eventHandler._startControlsTimeout();
        this.video.current.volume = this.state.volume;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sources !== this.props.sources) {
            this.eventHandler.handleSourcesChange();
            console.log("Has next episode?", this.props.hasNextEpisode ? "Yes" : "no")
        }
    }

    componentWillUnmount() {
        this._unmountVideoEvents();
        this._unmountControlsEvents();
        this._unmountSettingsEvents();
        this._unmountShortcutEvents();
        this._unmountSeekbarEvents();
    }

    _getPlatformType = () => {
        const agent = navigator.userAgent;
        console.log(agent);
        if (agent.includes("Mobile") || agent.includes("Android")) {
            if (agent.includes("iPhone")) {
                return "iOS";
            } else {
                return "Mobile"
            }
        } else {
            if (agent.includes("Macintosh")) {
                return "Macintosh";
            } else {
                return "PC";
            }
        }
    }



    /**
     * ######################################################################################################
     * for Events
     */
    _controlsEvents() {
        const _this = this;
        if (_this.controls.current)
            _this.controls.current.addEventListener("mouseenter", _this.eventHandler.mouseEntersControls, false);
        if (_this.controls.current)
            _this.controls.current.addEventListener("mouseleave", _this.eventHandler.mouseLeavesControls, false);
        if (_this.playPauseButton.current)
            _this.playPauseButton.current.addEventListener("mouseup", _this.eventHandler.clickOnPlayPause, false);
        if (_this.forwardButton.current)
            _this.forwardButton.current.addEventListener("mouseup", _this.eventHandler.clickOnForwardButton, false);
        if (_this.rewindButton.current)
            _this.rewindButton.current.addEventListener("mouseup", _this.eventHandler.clickOnRewindButton, false);
        if (_this.fullscreenButton.current)
            _this.fullscreenButton.current.addEventListener("mouseup", _this.eventHandler.clickOnFullscreenButton, false);
        if (_this.volumeButton.current)
            _this.volumeButton.current.addEventListener("mouseup", _this.eventHandler.onClickOnVolumeButton, false);
        if (_this.settingsButton.current)
            _this.settingsButton.current.addEventListener("mouseup", _this.eventHandler.onClickCogButton, false);

        if (_this.mobilePlay.current)
            _this.mobilePlay.current.addEventListener("click", _this.eventHandler.clickOnPlayPause, false);
        if (_this.mobileRewind.current)
            _this.mobileRewind.current.addEventListener("click", _this.eventHandler.clickOnRewindButton, false);
        if (_this.mobileForward.current)
            _this.mobileForward.current.addEventListener("click", _this.eventHandler.clickOnForwardButton, false);
    }

    _unmountControlsEvents() {
        const _this = this;
        if (_this.controls.current)
            _this.controls.current.removeEventListener("mouseenter", _this.eventHandler.mouseEntersControls, false);
        if (_this.controls.current)
            _this.controls.current.removeEventListener("mouseleave", _this.eventHandler.mouseLeavesControls, false);
        if (_this.playPauseButton.current)
            _this.playPauseButton.current.removeEventListener("mouseup", _this.eventHandler.clickOnPlayPause, false);
        if (_this.forwardButton.current)
            _this.forwardButton.current.removeEventListener("mouseup", _this.eventHandler.clickOnForwardButton, false);
        if (_this.rewindButton.current)
            _this.rewindButton.current.removeEventListener("mouseup", _this.eventHandler.clickOnRewindButton, false);
        if (_this.fullscreenButton.current)
            _this.fullscreenButton.current.removeEventListener("mouseup", _this.eventHandler.clickOnFullscreenButton, false);
        if (_this.volumeButton.current)
            _this.volumeButton.current.removeEventListener("mouseup", _this.eventHandler.onClickOnVolumeButton, false);
        if (_this.settingsButton.current)
            _this.settingsButton.current.removeEventListener("mouseup", _this.eventHandler.onClickCogButton, false);


        if (_this.mobilePlay.current)
            _this.mobilePlay.current.removeEventListener("click", _this.eventHandler.clickOnPlayPause, false);
        if (_this.mobileRewind.current)
            _this.mobileRewind.current.removeEventListener("click", _this.eventHandler.clickOnRewindButton, false);
        if (_this.mobileForward.current)
            _this.mobileForward.current.removeEventListener("click", _this.eventHandler.clickOnForwardButton, false);
    }

    _seekbarEvents() {
        const _this = this;
        const seekbar = _this.seekbar.current;
        seekbar.addEventListener("mouseenter", _this.eventHandler.onMouseEnterSeekbar, false);
        seekbar.addEventListener("mousemove", _this.eventHandler.onMouseMoveSeekbar, false);
        seekbar.addEventListener("mousedown", _this.eventHandler.onMouseDownSeekbar, false);
        seekbar.addEventListener("mouseleave", _this.eventHandler.onMouseLeaveSeekbar, false);
        document.addEventListener("fullscreenchange", _this.eventHandler.fullscreenChange, false);
    }

    _unmountSeekbarEvents() {
        const _this = this;
        const seekbar = _this.seekbar.current;
        seekbar.removeEventListener("mouseenter", _this.eventHandler.onMouseEnterSeekbar, false);
        seekbar.removeEventListener("mousemove", _this.eventHandler.onMouseMoveSeekbar, false);
        seekbar.removeEventListener("mousedown", _this.eventHandler.onMouseDownSeekbar, false);
        seekbar.removeEventListener("mouseleave", _this.eventHandler.onMouseLeaveSeekbar, false);
        document.removeEventListener("fullscreenchange", _this.eventHandler.fullscreenChange, false);
    }


    _videoEvents() {
        if (!this.video.current) {
            return false;
        }
        const _this = this;
        _this.video.current.addEventListener("loadedmetadata", _this.eventHandler.onLoadedMetaData, false);
        _this.video.current.addEventListener("durationchange", _this.eventHandler.onDurationChange, false);
        _this.video.current.addEventListener("suspend", _this.eventHandler.onSuspend, false);
        _this.video.current.addEventListener("stalled", _this.eventHandler.onStalled, false);
        _this.video.current.addEventListener("error", _this.eventHandler.onError, false);
        _this.video.current.addEventListener("canplay", _this.eventHandler.onVideoCanPlay, false);
        _this.video.current.addEventListener("canplaythrough", _this.eventHandler.onVideoCanPlay, false);
        _this.video.current.addEventListener("timeupdate", _this.eventHandler.onVideoTimeUpdate, false);
        _this.video.current.addEventListener("progress", _this.eventHandler.onVideoProgress, false);
        _this.video.current.addEventListener("play", _this.eventHandler.onVideoPlay, false);
        _this.video.current.addEventListener("pause", _this.eventHandler.onVideoPause, false);
        _this.video.current.addEventListener("waiting", _this.eventHandler.onVideoWaiting, false);
        _this.video.current.addEventListener("loadstart", _this.eventHandler.onVideoLoadStart, false);
        _this.video.current.addEventListener("playing", _this.eventHandler.onVideoPlaying, false);
        _this.video.current.addEventListener("volumechange", _this.eventHandler.onVolumechange, false);

        if (this.eventHandler._getPlatformType() === "Mobile" || this.eventHandler._getPlatformType() === "iOS") {
            console.log("This is mobile device")
            _this.video.current.addEventListener("touchend", _this.eventHandler.onClickOnVideo);
        } else {
            console.log("This is PC");
            _this.video.current.addEventListener("click", _this.eventHandler.onClickOnVideo);
            _this.video.current.addEventListener("mousemove", _this.eventHandler.mouseMoveOnVideo, false);
        }
    }

    _unmountVideoEvents() {
        const _this = this;
        _this.video.current.removeEventListener("loadedmetadata", _this.eventHandler.onLoadedMetaData, false);
        _this.video.current.removeEventListener("durationchange", _this.eventHandler.onDurationChange, false);
        _this.video.current.removeEventListener("suspend", _this.eventHandler.onSuspend, false);
        _this.video.current.removeEventListener("stalled", _this.eventHandler.onStalled, false);
        _this.video.current.removeEventListener("error", _this.eventHandler.onError, false);
        _this.video.current.removeEventListener("canplay", _this.eventHandler.onVideoCanPlay, false);
        _this.video.current.removeEventListener("canplaythrough", _this.eventHandler.onVideoCanPlayThrough, false);
        _this.video.current.removeEventListener("timeupdate", _this.eventHandler.onVideoTimeUpdate, false);
        _this.video.current.removeEventListener("progress", _this.eventHandler.onVideoProgress, false);
        _this.video.current.removeEventListener("play", _this.eventHandler.onVideoPlay, false);
        _this.video.current.removeEventListener("pause", _this.eventHandler.onVideoPause, false);
        _this.video.current.removeEventListener("waiting", _this.eventHandler.onVideoWaiting, false);
        _this.video.current.removeEventListener("loadstart", _this.eventHandler.onVideoLoadStart, false);
        _this.video.current.removeEventListener("playing", _this.eventHandler.onVideoPlaying, false);
        _this.video.current.removeEventListener("volumechange", _this.eventHandler.onVolumechange, false);

        if (this.eventHandler._getPlatformType() === "Mobile" || this.eventHandler._getPlatformType() === "iOS") {
            _this.wrapper.current.removeEventListener("touchend", _this.eventHandler.onClickOnVideo, false);
        } else {
            _this.video.current.removeEventListener("click", _this.eventHandler.onClickOnVideo, false);
            _this.video.current.removeEventListener("mousemove", _this.eventHandler.mouseMoveOnVideo, false);
        }
    }


    _shortcutEvents() {
        const _this = this;
        window.addEventListener("keydown", _this.eventHandler.shortcutEvents, false);
    }

    _unmountShortcutEvents() {
        const _this = this;
        window.removeEventListener("keydown", _this.eventHandler.shortcutEvents, false);
    }

    _settingsEvents() {
        const headers = document.querySelectorAll(".splayer_settings-section-header");
        for (const header of headers) {
            header.addEventListener("mouseup", this.eventHandler.onClickSectionHeader, false)
        }
    }

    _unmountSettingsEvents() {
        const headers = document.querySelectorAll(".splayer_settings-section-header");
        for (const header of headers) {
            header.removeEventListener("mouseup", this.eventHandler.onClickSectionHeader, false);
        }
    }

    /**
     * end of Events
     * ######################################################################################################
     */



    render() {
        if (this.eventHandler._getPlatformType() === "Mobile" || this.eventHandler._getPlatformType() === "iOS") {
            return (
                <div className="player" ref={this.wrapper}>
                    {
                        typeof this.props.backNavigation === "function" &&
                        <div ref={this.backButton} className="back-button" onClick={() => this.props.backNavigation()}/>
                    }
                    {
                        this.props.videoTitle && (
                            <div ref={this.title} className="video-title">
                                {this.props.videoTitle}
                                <br style={{fontSize: 0, height: 0}}/>
                                {this.props.chapterTitle && (<span
                                    style={{fontSize: 14, lineHeight: 1, color: "#fff"}}>{this.props.chapterTitle}</span>)}
                            </div>
                        )
                    }
                    <video
                        ref={this.video}
                        preload="metadata"
                        controls={false}
                        width="100%"
                        height="100%"
                    >
                        <source src={this.state.currentQuality?.src} type={this.state.currentQuality?.type}/>
                    </video>
                    <div ref={this.controls} className="controls">
                        <div ref={this.seekbar} className="seekbar">
                            <div ref={this.thumb} className="thumb"/>
                            <div ref={this.playingProgress} className="playing-overlay"/>
                            <div ref={this.bufferedProgress} className="buffered-overlay"/>
                            <div ref={this.seekbarTimeTooltip} className="seekbar-time">--:--</div>
                        </div>

                        <button ref={this.playPauseButton}
                                type="button" data-state="play"
                                className="playpause-button"></button>
                        <button ref={this.rewindButton} type="button" className="rewind-button"></button>
                        <button ref={this.forwardButton} type="button" className="forward-button"></button>
                        <div ref={this.timeIndicator} className="video-time">
                            <span ref={this.currentTimeEl}>--:--</span> / <span ref={this.durationEl}>--:--</span>
                        </div>
                        {
                            this.props.isSeries &&
                            <button onClick={this.onClickOnEpisodes} className="episodes-button"></button>
                        }
                        {
                            this.props.hasNextEpisode &&
                            <button onClick={this.onClickNextEpisode} className="next-episode-button"/>
                        }
                        <button ref={this.settingsButton} className="cog-button"></button>
                        <button ref={this.fullscreenButton} type="button" data-state="fs-enter"
                                className="fullscreen-button"></button>
                    </div>
                    <div ref={this.settings} className="splayer_settings">
                        <div ref={this.settingsBody} className="splayer_settings-body">
                            <div ref={this.settings_mainSection} id="main-section" className="splayer_settings-section">
                                <div className="splayer_settings-item"
                                     onClick={() => this.eventHandler._changeSettingsSection(this.settings_speedSection.current)}>
                                    Speed
                                    <div className="spd-label">{this.currentSpeed.label}</div>
                                </div>
                                <div className="splayer_settings-item"
                                     onClick={() => this.eventHandler._changeSettingsSection(this.settings_qualitySection.current)}>
                                    Quality
                                    <div className="qlt-label">{this.state.currentQuality?.label}</div>
                                </div>
                            </div>
                            <div ref={this.settings_qualitySection} id="quality-section"
                                 className="splayer_settings-section">
                                <div className="splayer_settings-section-header">
                                    Quality
                                </div>
                                {
                                    this.props.sources &&
                                    this.props.sources.map((qlty, index) => (
                                        <div
                                            key={`speed-${index}`}
                                            className="splayer_settings-item"
                                            onClick={() => this.eventHandler._handleClickOnQualityItem(qlty)}
                                        >
                                            {qlty.label}
                                        </div>
                                    ))
                                }
                            </div>
                            <div ref={this.settings_speedSection} id="speed-section"
                                 className="splayer_settings-section">
                                <div className="splayer_settings-section-header">
                                    Quality
                                </div>
                                {
                                    this.speeds.map((speed, index) => (
                                        <div
                                            key={`speed-${index}`}
                                            className="splayer_settings-item"
                                            onClick={() => this.eventHandler._handleClickOnSpeedItem(speed)}
                                        >
                                            {speed.label}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div ref={this.spinnerEl} className="spinner"/>
                    {/*<div ref={this.playIndicator} className="indicator-elements play-button" data-state="play"/>*/}
                    {/*<div ref={this.volumeIndicator} className="indicator-elements volume-control"
                         data-state="volume_loud"/>*/}
                    {/*<div ref={this.rewinded} className="indicator-elements rewinded"/>*/}
                    {/*<div ref={this.forwarded} className="indicator-elements forwarded"/>*/}

                    {/*<button ref={this.mobileRewind} className="mobile-control mobile-rewind "/>*/}
                    {/*<button ref={this.mobilePlay} className="mobile-control mobile-play " data-state="play"/>*/}
                    {/*<button ref={this.mobileForward} className="mobile-control mobile-forward "/>*/}
                </div>
            )
        } else {
            return (
                <div className="player" ref={this.wrapper}>
                    {
                        typeof this.props.backNavigation === "function" &&
                        <div ref={this.backButton} className="back-button" onClick={() => this.props.backNavigation()}/>
                    }
                    <div ref={this.title} className="video-title">
                        {this.props.videoTitle && this.props.videoTitle}
                        {this.props.chapterTitle && (
                            <>
                                <br style={{fontSize: 0, height: 0}}/>
                                <span style={{fontSize: 14, lineHeight: 1, color: "#fff"}}>
                                    {this.props.chapterTitle}
                                </span>
                            </>
                        )}
                    </div>
                    <video
                        ref={this.video}
                        preload="metadata"
                        controls={false}
                        width="100%"
                        height="100%"
                    >
                        <source src={this.state.currentQuality?.src} type={this.state.currentQuality?.type}/>
                    </video>
                    <div ref={this.controls} className="controls hidden">
                        <div ref={this.seekbar} className="seekbar">
                            <div ref={this.thumb} className="thumb"/>
                            <div ref={this.playingProgress} className="playing-overlay"/>
                            <div ref={this.bufferedProgress} className="buffered-overlay"/>
                            <div ref={this.seekbarTimeTooltip} className="seekbar-time">--:--</div>
                        </div>
                        <button ref={this.playPauseButton}
                                type="button" data-state="play"
                                className="playpause-button"></button>
                        <button ref={this.rewindButton} type="button" className="rewind-button"></button>
                        <button ref={this.forwardButton} type="button" className="forward-button"></button>
                        <button ref={this.volumeButton} type="button" data-state="volume_loud"
                                className="volume-button"></button>
                        <div ref={this.timeIndicator} className="video-time">
                            <span ref={this.currentTimeEl}>--:--</span> / <span ref={this.durationEl}>--:--</span>
                        </div>
                        {
                            this.props.isSeries &&
                            <button onClick={this.onClickOnEpisodes} className="episodes-button"></button>
                        }
                        {
                            this.props.hasNextEpisode &&
                            <button onClick={this.onClickNextEpisode} className="next-episode-button"/>
                        }
                        <button ref={this.settingsButton} className="cog-button"></button>
                        <button ref={this.fullscreenButton} type="button" data-state="fs-enter"
                                className="fullscreen-button"></button>
                    </div>
                    <div ref={this.settings} className="splayer_settings">
                        <div ref={this.settingsBody} className="splayer_settings-body">
                            <div ref={this.settings_mainSection} id="main-section" className="splayer_settings-section">
                                <div className="splayer_settings-item"
                                     onClick={() => this.eventHandler._changeSettingsSection(this.settings_speedSection.current)}>
                                    Speed
                                    <div className="spd-label">{this.currentSpeed.label}</div>
                                </div>
                                <div className="splayer_settings-item"
                                     onClick={() => this.eventHandler._changeSettingsSection(this.settings_qualitySection.current)}>
                                    Quality
                                    <div className="qlt-label">{this.state.currentQuality?.label}</div>
                                </div>
                            </div>
                            <div ref={this.settings_qualitySection} id="quality-section"
                                 className="splayer_settings-section">
                                <div className="splayer_settings-section-header">
                                    Quality
                                </div>
                                {
                                    this.props.sources &&
                                    this.props.sources.map((qlty, index) => (
                                        <div
                                            key={`speed-${index}`}
                                            className="splayer_settings-item"
                                            onClick={() => this.eventHandler._handleClickOnQualityItem(qlty)}
                                        >
                                            {qlty.label}
                                        </div>
                                    ))
                                }
                            </div>
                            <div ref={this.settings_speedSection} id="speed-section"
                                 className="splayer_settings-section">
                                <div className="splayer_settings-section-header">
                                    Quality
                                </div>
                                {
                                    this.speeds.map((speed, index) => (
                                        <div
                                            key={`speed-${index}`}
                                            className="splayer_settings-item"
                                            onClick={() => this.eventHandler._handleClickOnSpeedItem(speed)}
                                        >
                                            {speed.label}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div ref={this.spinnerEl} className="spinner"/>
                    <div ref={this.playIndicator} className="indicator-elements play-button" data-state="play"/>
                    <div ref={this.volumeIndicator} className="indicator-elements volume-control"
                         data-state="volume_loud"/>
                    <div ref={this.rewinded} className="indicator-elements rewinded"/>
                    <div ref={this.forwarded} className="indicator-elements forwarded"/>
                </div>
            )

        }
    }


}

ReactSplayer.propTypes = {
    sources: PropTypes.array.isRequired,
    videoTitle: PropTypes.string,
    chapterTitle: PropTypes.string,
    isSeries: PropTypes.bool,
    fullscreenAuto: PropTypes.bool,
    backNavigation: PropTypes.func,
    onClickEpisodes: PropTypes.func,
    onClickNextEpisode: PropTypes.func,
    canplay: PropTypes.func,
    canplaythrough: PropTypes.func,
    durationchange: PropTypes.func,
    emptied: PropTypes.func,
    ended: PropTypes.func,
    error: PropTypes.func,
    play: PropTypes.func,
    playing: PropTypes.func,
    pause: PropTypes.func,
    loadeddata: PropTypes.func,
    loadedmetadata: PropTypes.func,
    loadstart: PropTypes.func,
    progress: PropTypes.func,
    ratechange: PropTypes.func,
    seeked: PropTypes.func,
    seeking: PropTypes.func,
    stalled: PropTypes.func,
    suspend: PropTypes.func,
    timeupdate: PropTypes.func,
    volumechange: PropTypes.func,
    waiting: PropTypes.func,
    hasNextEpisode: PropTypes.bool,
}

ReactSplayer.defaultProps = {
    videoTitle: "",
    chapterTitle: "",
    sources: [],
    isSeries: false,
    fullscreenAuto: false,
    backNavigation: null,
    hasNextEpisode: false,
    onClickOnEpisodes: () => {},
    onClickNextEpisode: () => {},
}

export default ReactSplayer;
