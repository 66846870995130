import React, {useEffect, useRef, useState,} from "react";
import {Link, useNavigate} from "react-router-dom";
import "../../components/form/form.css";
import ArrowRight from "../../components/icons/ArrowRight";
import User from "../../components/icons/User";
import Key from "../../components/icons/Key";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import {useTokenStore, useUserStore} from '../../constans/StoreUserData';
import Eye from "../../components/icons/Eye";
import EyeClosed from "../../components/icons/EyeClosed";
import {checkUserSession} from "../../utils/checkUser";

function Signin() {

    const navigate = useNavigate();

    const userRef = useRef(null);
    const errRef = useRef();

    const { user, setUser, clearUser } = useUserStore();
    const { token, setToken } = useTokenStore();


    const [loginname, setLoginname] = useState("")
    const [password, setPassword] = useState("");


    const [passwordType, setPasswordType] = useState("password");
    const [error, setError] = useState("");

    const [session, setSession] = useState(null);


    useEffect(() => {
        if (userRef.current) {
            userRef.current.focus();
        }
        if (user && typeof user === "object") {
            navigate("/home");
        }
        checkUserSession().then(async value => {
            await fetchCsrfToken();
            setSession(value)
        });
    }, []);

    useEffect(() => {
        setError("");
    }, [loginname, password]);

    useEffect(() => {
        if (session) {
            setUser(session);
        } else {
            clearUser();
        }
    }, [session]);




    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const form = { loginname, password }
            const response = await postJson(`${API_URL}/api/core/signin`, form);
            if (response.success) {
                await getSignedUser();
                navigate("/")
            } else {
                setError("Нэвтрэх нэр эсвэл нууц үг буруу байна");
                console.warn("WARN RESPONSE:", response.message);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getSignedUser = async () => {
        try {
            const response = get(`${API_URL}/api/core/signedUser`);
            if ((await response).success) {
                setUser((await response).payload);
            } else {
                setError((await response).message);
                console.warn("Signed User fetch error", (await response).message);

            }
        } catch (e) {
            console.error(e)
        }
    }

    const fetchCsrfToken = async () => {
        try {
            const response = await get(`${API_URL}/api/core/csrf`);
            if ((await response).success) {
                // console.log("Toke:", (await response));
                setToken((await response).payload);
            } else {
                console.warn("Token fetch error", (await response).message);
            }
        } catch (e) {

        }
    }


    const togglePassword = (e) => {
        e.preventDefault();
        setPasswordType(passwordType === 'password' ? 'text' : 'password');
    };

    return (
        <>
            <div>
                <div className="container-log">
                    <form onSubmit={handleSubmit} className={"login"}>
                        <>
                            <div>
                                <div className={"input-container"}>
                                    <User stroke={"#8F8D8D"}/>
                                    <input
                                        value={loginname}
                                        placeholder="Нэвтрэх нэр/Утасны дугаар"
                                        onChange={(e) => setLoginname(e.target.value)}
                                        className={"input"}
                                        autoComplete={"off"}
                                        required
                                    />
                                </div>
                                <div className={"input-container mt-2"} >
                                    <Key stroke={"#8F8D8D"}/>
                                    <input
                                        type={passwordType}
                                        value={password}
                                        placeholder="Нууц үг"
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={"input"}
                                        required
                                    />
                                    <button type="button" onClick={(e) => togglePassword(e)}>
                                        {passwordType === "password" ? <Eye/> : <EyeClosed/>}
                                    </button>
                                </div>
                            </div>


                            {
                                error &&
                                <label ref={errRef} aria-live={"assertive"} className="errorLabel">{error}</label>
                            }
                            <div className={"forget"}>
                                <Link to="/recovery">
                                    Нууц үг мартсан
                                </Link>
                                <ArrowRight color="#7A7978"/>
                            </div>
                            <button className="button-md button-primary">
                                Нэвтрэх
                            </button>
                        </>
                    </form>
                </div>
            </div>

        </>
    );
}

export default Signin;
