import React, {useEffect, useRef, useState} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import CardOngoing from "../../cards/CardOngoing";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import MovieModal from "../../modal/MovieModal";
import CustomPopup from "../../common/pop-up/CustomPopup";
import Slider from "react-slick";
import ChevronLeft from "../../icons/ChevronLeft";
import ChevronRight from "../../icons/ChevronRight";


export default function Ongoing() {

    const [data, setData] = useState([]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        getOngoingMovie();
    }, []);


    const getOngoingMovie = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?movieStatus=ONGOING`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements.slice(0,16));
            } else {
                console.warn("getOngoingMovie", (await response).message);
            }
        } catch (e) {
        }
    }

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }


    let settings = {
        dots: false,
        infinite: true,
        fade: false,
        arrows: false,
        speed: 800,
        slidesToShow: 8,
        slidesToScroll: 4,
        autoplay: false,
        autoplaySpeed: 3500,
        className: "thisYear-slider-container",
        dotsClass: "thisYear-slider-pagination",
        beforeChange: () => setIsSliding(true),
        afterChange: () => setIsSliding(false),
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                }
            }
        ]
    };
    const [isSliding, setIsSliding] = useState(false);

    const slickRef = useRef()
    const goNext = () => {
        if (slickRef.current)
            slickRef.current.slickNext()
    }
    const goPrev = () => {
        if (slickRef.current)
            slickRef.current.slickPrev()
    }


    const [isDragging, setIsDragging] = useState(false);
    const handleMouseDown = () => {
        setIsDragging(false); // Reset dragging state
    };

    const handleMouseMove = () => {
        setIsDragging(true); // Pointer is moving, consider it a drag
    };

    const handleMouseUp = () => {
        if (!isDragging) {
            // If not dragging, trigger the popup
            // alert('Popup triggered!');
        }
        setIsDragging(false); // Reset dragging state after action
    };

    return (
        <>
            <div className={"container"}>
                <div className="ongoing mt-5 gap-2" >
                    <HeaderCard  title={"Орчуулж байгаа анимэнууд"} link={'anime-ongoing'} />
                    <div onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                        <Slider {...settings} ref={slickRef} >
                            {data.map((item, index) => (
                                <CardOngoing key={index} item={item} onClick={() => {
                                    if (!isDragging)
                                        openDialog(item)
                                }} />
                            ))}
                        </Slider>
                    </div>
                    <div className="this-year-prev" onClick={goPrev}>
                        <ChevronLeft/>
                    </div>
                    <div className="this-year-next" onClick={goNext}>
                        <ChevronRight/>
                    </div>
                </div>
            </div>
            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>
        </>
    )
}
