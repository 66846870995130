import React, {useEffect, useRef, useState} from "react";
import "./pagnition.css"
import './featureSwiper.css'
import 'swiper/css/navigation';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SlideParallaxContext from "./SlideParallaxContext";
import PauseCircle from "../icons/PauseCircle";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";
import PlayCircle from "../icons/PlayCircle";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";


function FeaturedSwiper() {

    const [hideContext, setHideContext] = useState(false);
    const [bottomValue, setBottomValue] = useState(20);

    const [enableAutoplay, setEnableAutoplay] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const dots = document.querySelectorAll('.feature-slider-pagination li');
    const activeDot = document.querySelectorAll('.feature-slider-pagination li')[currentIndex];

    const slickRef = useRef(null);
    const controlsRef = useRef(null);

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchFeature();
    }, []);

    const fetchFeature = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/feature?positionAlias=main`);
            if ((await response).success) {
                setData((await response).payload);
            } else {
                console.warn("fetchFeature", (await response));
            }
        } catch (e) {
        }
    }




    useEffect(() => {
        setTimeout(() => {
            setEnableAutoplay(true);
        }, 1000);
        if (controlsRef.current) {
            if (window.innerWidth > 1440) {
                controlsRef.current.style.right = `${(window.innerWidth - 1440) / 2}px`
            }
        }
    }, []);


    useEffect(() => {
        if (slickRef.current) {
            if (enableAutoplay) {
                restartAutoPlay();
                slickRef.current.slickPlay();
            } else {
                slickRef.current.slickPause();
                // clearActiveDot();
            }
        }
    }, [enableAutoplay]);

    useEffect(() => {
        createActiveDot();
    }, [currentIndex])

    window.onresize = function (ev) {
        if (controlsRef.current) {
            if (window.innerWidth > 1440)
                controlsRef.current.style.right = `${(window.innerWidth - 1440) / 2}px`
            else
                controlsRef.current.style.right = `0`
        }
    }


    const createSliderDots = () => {
        const slides = document.querySelectorAll(".feature-slider-container .slick-track .slick-slide");
        const paginationTarget = document.querySelector(".feature-slider-pagination");
        if (slides.length > 1) {
            paginationTarget.innerHTML = "";
            const ul = document.createElement("ul");
            for (let i = 0; i < slides.length; i++) {
                const li = document.createElement("li");
                const div = document.createElement("div");
                div.addEventListener("click", function () {
                    if (slickRef.current) {
                        slickRef.current.slickGoTo(i);
                        restartAutoPlay();
                    }
                })
                li.appendChild(div);
                ul.appendChild(li);
            }
            paginationTarget.appendChild(ul);
        }
    }

    const restartAutoPlay = () => {
        clearActiveDot();
        setTimeout(() => {
            createActiveDot();
        }, 300)
    }

    const clearActiveDot = () => {
        dots.forEach(el => {
            el.classList.remove("active-dot");
        })
    }

    const createActiveDot = () => {
        if (activeDot)
            activeDot.classList.add("active-dot");
    }

    const goNext = () => {
        if (slickRef)
            slickRef.current.slickNext();
    }

    const goPrev = () => {
        if (slickRef)
            slickRef.current.slickPrev();
    }

    let settings = {
        dots: false,
        infinite: true,
        fade: true,
        arrows: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: enableAutoplay,
        autoplaySpeed: 3500,
        className: "feature-slider-container",
        dotsClass: "feature-slider-pagination",
        customPaging: (i) => {
            return (
                <div></div>
            )
        },
        onInit: () => {
            if (slickRef) {
                // console.log(slickRef.current);
                setTimeout(() => {
                    createSliderDots();
                }, 500)
            }
        },
        beforeChange: (oldIndex, newIndex) => {
            // console.log(newIndex);
            setCurrentIndex(newIndex);
            if (enableAutoplay) {
                clearActiveDot();
            }
        },
        afterChange: (newIndex) => {
        }
    };

    return (
        <>
            <div className="slider-container">
                <Slider
                    {...settings}
                    ref={slickRef}
                    onMouseOver={(e) => {}}
                >
                    {
                        data && data.map((item, i)=> {
                            let imgSrc = "";
                            if (item.image)
                                imgSrc = `https://admin.mnfansubs.net/resource/${item.image.path}.${item.image.ext}`;
                            else
                                imgSrc = `https://admin.mnfansubs.net/resource/${item.movie?.movieImage?.path}.${item.movie?.movieImage?.ext}`;
                            return (
                                <>
                                    <div key={`${item.id}`}>
                                        <div>
                                            <div className="slider-image">
                                                <img src={imgSrc} alt=""/>
                                                <div className="swiper-overlay-gradient-top"/>
                                                <div className="swiper-overlay-gradient-bottom"/>
                                                <SlideParallaxContext item={item}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            }
                        )

                    }

                </Slider>
                <div ref={controlsRef} className="feature-control-box">
                    <div className="wrapper">
                        <div className={"gap-2"}>
                            <button className="button-sm button-secondary-outline" onClick={goPrev}>
                                <ArrowLeft/>
                            </button>
                            <button onClick={() => {
                                setEnableAutoplay(!enableAutoplay)
                            }} className="button-sm button-secondary-outline">
                                {
                                    enableAutoplay ?
                                        <PauseCircle/>
                                        :
                                        <PlayCircle/>
                                }
                            </button>
                            <div className="feature-slider-pagination"/>
                            <button className="button-sm button-secondary-outline" onClick={goNext}>
                                <ArrowRight/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeaturedSwiper;
