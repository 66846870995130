import React from 'react';

class ReactSplayerEvents {

    constructor(instance) {
        this.instance = instance;
        this.isMouseMoving = false;
        this.mouseMoveTimer = null;
        this.overrideTimeout = false;
        this.controlsShown = false;
    }

    storedVolume = localStorage.getItem("splayer-volume");

    _getPlatformType = () => {
        const instance = this.instance;
        const agent = navigator.userAgent;
        if (agent.includes("Mobile") || agent.includes("Android")) {
            instance.platform = "mobile";
            if (agent.includes("iPhone")) {
                return "iOS";
            } else {
                return "Mobile"
            }
        } else {
            instance.platform = "PC";
            if (agent.includes("Macintosh")) {
                return "Macintosh";
            } else {
                return "PC";
            }
        }
    }

    onLoadedMetaData = async (e) => {
        try {
            const instance = this.instance;
            const width = e.target.videoWidth;
            const height = e.target.videoHeight;
            const aspectRatio = width / height;
            if (instance.wrapper.current && instance.video.current) {
                // instance.wrapper.current.style.aspectRatio = aspectRatio.toFixed(3);
                // instance.video.current.style.aspectRatio = aspectRatio.toFixed(3);
            }
            if (instance && instance.props.loadedmetadata)
                instance.props.loadedmetadata(e);
            if (instance && instance.video.current) {
                // await this.toggleVolume();
                // await this._playVideo();
                setTimeout(() => {
                    // this.toggleVolume();
                }, 300)
            }
        } catch (e) {
            console.error("Error loading metadata" + e);
        }
    }

    onDurationChange(e) {
        const instance = this.instance;
        if (instance && instance.props && instance.props.durationchange)
            instance.props.durationchange(e)
    }

    onSuspend = (e) => {
        const instance = this.instance;
        if (instance && instance.props && instance.props.suspend)
            instance.props.suspend(e);
    }

    onStalled = (e) => {
        const instance = this.instance;
        if (instance && instance.props && instance.props.stalled)
            instance.props.stalled(e);
    }

    onError = (e) => {
        const instance = this.instance;
        if (instance && instance.props && instance.props.error)
            instance.props.error(e);
    }

    mouseMoveOnVideo = (e) => {
        clearTimeout(this.mouseMoveTimer);
        this.mouseMoveTimer = setTimeout(() => {
            this._hideControls();
        }, 1500)
        const instance = this.instance;
        if (instance && !this.controlsShown) {
            this._showControls();
            // this._startControlsTimeout();
        }
    }

    onClickOnVideo = (e) => {
        const instance = this.instance;
        if (this._getPlatformType() === "PC" || this._getPlatformType() === "Macintosh") {
            this._toggleControls();
            if (instance.video.current.paused || instance.video.current.ended) {
                this._playVideo();
            } else if (instance.video.current.play) {
                this._pauseVideo();
            }
        } else {
            this._toggleControls();
        }
    }

    onVideoCanPlay = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.props && instance.props.canplay)
                instance.props.canplay(e);
            instance.spinnerEl.current.style.opacity = 0;
            instance.setState({canplay: true});
            instance.durationEl.current.innerText = this._formatTime(instance.video.current.duration);
            instance.currentTimeEl.current.innerText = this._formatTime(0);
        }
    }

    onVideoCanPlayThrough = (e) => {
        const instance = this.instance;
        if (instance && instance.props && instance.props.canplaythrough)
            instance.props.canplaythrough(e);
    }

    onVideoTimeUpdate = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.props && instance.props.timeupdate)
                instance.props.timeupdate(e);
            instance.currentTime = instance.video.current.currentTime;
            instance.currentTimeEl.current.innerText = this._formatTime(instance.video.current.currentTime);
            this._changeSeekbarProgress();
        }
    }

    onVideoProgress = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.props && instance.props.progress)
                instance.props.progress(e);
            this._changeBufferedProgress();
        }
    }

    onVideoPlay = (e) => {
        const instance = this.instance;
        if (instance) {
            this._revokeOverrideControlsTimeout();
            if (instance.props && instance.props.play)
                instance.props.play(e);
            instance.playing = true;
            if (instance.playPauseButton.current)
                instance.playPauseButton.current.setAttribute("data-state", "pause");
            if (instance.playIndicator.current)
                instance.playIndicator.current.setAttribute("data-state", "play");
            if (instance.mobilePlay.current)
                instance.mobilePlay.current.setAttribute("data-state", "pause");
            if (instance.playIndicator.current)
                instance.playIndicator.current.style.opacity = "1";
            if (instance.playIndicator.current) {
                setTimeout(() => {
                    instance.playIndicator.current.style.opacity = "0";
                }, 300)
            }
        }
    }

    onVideoPause = (e) => {
        const instance = this.instance;
        if (instance) {
            this._overrideControlsTimeout();
            if (instance.props && instance.props.pause)
                instance.props.pause(e);
            instance.playing = false;
            if (instance.playPauseButton.current)
                instance.playPauseButton.current.setAttribute("data-state", "play");
            if (instance.playIndicator.current)
                instance.playIndicator.current.setAttribute("data-state", "pause");
            if (instance.mobilePlay.current)
                instance.mobilePlay.current.setAttribute("data-state", "play");
            if (instance.playIndicator.current)
                instance.playIndicator.current.style.opacity = "1";
            if (instance.playIndicator.current) {
                setTimeout(() => {
                    instance.playIndicator.current.style.opacity = "0";
                }, 300)
            }
        }
    }

    onVideoWaiting = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.props && instance.props.waiting)
                instance.props.waiting(e);
            instance.spinnerEl.current.style.opacity = 1;
        }
    }

    onVideoLoadStart = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.props && instance.props.loadstart)
                instance.props.loadstart(e);
            if (instance.spinnerEl)
                instance.spinnerEl.current.style.opacity = 1;
        }
    }

    onVideoPlaying = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.props && instance.props.playing)
                instance.props.playing(e);
            if (instance.spinnerEl)
                instance.spinnerEl.current.style.opacity = 0;
        }
    }

    onVolumechange = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.props && instance.props.volumechange) {
                instance.props.volumechange(e);
            }
            const video = instance.video.current;
            const volume = instance.video.current.volume;
            const volumeButton = instance.volumeButton.current;
            const volumeIndicator = instance.volumeIndicator.current;
            localStorage.setItem("splayer-volume", volume);
            if (volume >= .1 && volume <= .3) {
                volumeButton?.setAttribute("data-state", "volume_low");
                volumeIndicator?.setAttribute("data-state", "volume_low");
            }
            if (volume >= .4 && volume <= .7) {
                volumeButton?.setAttribute("data-state", "volume_medium");
                volumeIndicator?.setAttribute("data-state", "volume_medium");
            }
            if (volume >= .8 && volume === 1) {
                volumeButton?.setAttribute("data-state", "volume_loud");
                volumeIndicator?.setAttribute("data-state", "volume_loud");
            }
            if (volume === 0) {
                volumeButton?.setAttribute("data-state", "volume_muted");
                volumeIndicator?.setAttribute("data-state", "volume_muted");
            }
            if (video.muted) {
                volumeButton?.setAttribute("data-state", "volume_muted");
                volumeIndicator?.setAttribute("data-state", "volume_muted");
            }
            if (volumeIndicator) {
                volumeIndicator.style.opacity = 1;
                setTimeout(() => {
                    volumeIndicator.style.opacity = 0;
                }, 300)
            }
        }
    }

    // ###### end of video events ######

    // ###### Shortcuts ######
    shortcutEvents = (e) => {
        const instance = this.instance;
        if (instance && instance.video) {
            const paused = instance.video.current.paused;
            const ended = instance.video.current.ended;
            // Arrow left
            if (e.keyCode == "37")
                this.forwardRewind("rewind");
            // Arrow right
            else if (e.keyCode == "39")
                this.forwardRewind("forward");
            // Arrow up
            else if (e.keyCode == "38") {
                this.volumeIncrease();
            }
            // Arrow down
            else if (e.keyCode == "40") {
                this.volumeDecrease();
            }
            // Space key
            else if (e.keyCode == "32") {
                if (paused || ended) {
                    this._playVideo();
                } else {
                    this._pauseVideo();
                }
            }
            // F key
            else if (e.keyCode == "70") {
                this.toggleFullscreen();
            }
        }
    }

    // ###### Settings ######
    onClickSectionHeader = (e) => {
        this._resetSettingsSection();
    }


    // ###### Controls events ######
    mouseEntersControls = (e) => {
        this._overrideControlsTimeout();
    }

    mouseLeavesControls = () => {
        const instance = this.instance;
        if (instance && instance.settingsShown) {
            this._hideControls();
        } else {
            this._revokeOverrideControlsTimeout();
        }
    }

    clickOnPlayPause = (e) => {
        const instance = this.instance;
        const paused = instance.video.current.paused;
        const ended = instance.video.current.ended;
        if (instance) {
            if (paused || ended) {
                this._playVideo();
                instance.spinnerEl.current.style.opacity = 0;
            } else if (instance.video.current.play) {
                this._pauseVideo();
            }
        }
    }

    clickOnRewindButton = (e) => {
        const instance = this.instance;
        if (!instance.controls.current.classList.contains("hidden"))
            this.forwardRewind("rewind");
    }

    clickOnForwardButton = (e) => {
        const instance = this.instance;
        if (!instance.controls.current.classList.contains("hidden"))
            this.forwardRewind("forward");
    }

    clickOnFullscreenButton = (e) => {
        this.toggleFullscreen();
    }

    onClickOnVolumeButton = (e) => {
        this.toggleVolume();
    }

    onClickCogButton = (e) => {
        const instance = this.instance;
        if (instance) {
            if (instance.settingsShown) {
                this._hideSettings();
            } else {
                this._showSettings();
                document.onclick = this.onClickOutsideSettings;
            }
        }
    }

    onClickOutsideSettings = (e) => {
        const instance = this.instance;
        if (instance) {
            if (e.composedPath() && !e.composedPath().includes(instance.settings.current)) {
                if (instance.settingsShown && e.target !== instance.settingsButton.current) {
                    this._hideSettings();
                    document.onclick = null
                }
            }
        }
    }


    // ###### Seekbar events ######
    fullscreenChange = (e) => {
        const instance = this.instance;
        const state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if (instance) {
            if (!state) {
                instance.fullscreenButton.current.setAttribute("data-state", "fs-enter");
            } else {
                instance.fullscreenButton.current.setAttribute("data-state", "fs-exit");
            }
        }
    }

    onMouseEnterSeekbar = (e) => {

    }

    onMouseDownSeekbar = async (e) => {
        const instance = this.instance;
        e.stopPropagation();
        e.preventDefault();
        if (instance) {
            const {currentTime, timePos, newPos} = await this.calcTimeTooltip(e);
            instance.video.current.currentTime = currentTime;

            document.onmousemove = this.handleDragThumb;
            document.onmouseup = this.handleSeekbarRelease;
        }
    }

    onMouseMoveSeekbar = async (e) => {
        await this.transformTimeTooltip(e);
    }

    onMouseLeaveSeekbar = (e) => {
        this.hideTimeTooltip(e);
    }

    handleDragThumb = async (e) => {
        const instance = this.instance;
        e.preventDefault();
        e.stopPropagation();
        const {currentTime, timePos, newPos} = await this.calcTimeTooltip(e);
        if (instance) {
            instance.playingProgress.current.style.width = `${newPos}px`;
            instance.thumb.current.style.left = `${newPos}px`;

            this._showControls();
            this._overrideControlsTimeout();
            await this.transformTimeTooltip(e);
            instance.video.current.currentTime = currentTime;
        }
    }

    handleSeekbarRelease = (e) => {
        this.hideTimeTooltip(e);
        document.onmousemove = null;
        document.onmouseup = null;
    }

    // ###### Time tooltip method ######
    transformTimeTooltip = async (e) => {
        const instance = this.instance;
        const tooltip = instance.seekbarTimeTooltip.current;
        const {currentTime, timePos, newPos} = await this.calcTimeTooltip(e);
        if (instance) {
            tooltip.style.left = `${timePos}px`;
            tooltip.innerText = this._formatTime(currentTime);
            tooltip.style.opacity = 1;
        }
    }

    calcTimeTooltip = async (e) => {
        try {
            const instance = this.instance;
            const seekbarRect = instance.seekbar.current.getBoundingClientRect();
            const timeRect = instance.seekbarTimeTooltip.current.getBoundingClientRect();
            const relativeOffsetX = e.clientX - seekbarRect.left;
            const newPos = Math.max(0, Math.min(relativeOffsetX, seekbarRect.width));
            const currentTime = await (newPos / seekbarRect.width) * instance.video.current.duration;
            const timePos = await (newPos - (timeRect.width / 2));

            return {currentTime, timePos, newPos}
        } catch (e) {
            console.warn(e)
        }
    }

    hideTimeTooltip = (e) => {
        const instance = this.instance;
        instance.seekbarTimeTooltip.current.style.opacity = 0;
    }

    handleSourcesChange = (e) => {
        const instance = this.instance;
        if (instance && instance.props && instance.props.sources)
            instance.setState({currentQuality: instance.props.sources[0]});
        setTimeout(() => {
            instance.video.current.load();
        }, 300)
    }


    /**
     * for Methods
     * ######################################################################################################
     */
    forwardRewind = (dir) => {
        const instance = this.instance;
        const canplay = instance.state.canplay;
        const video = instance.video.current;
        if (instance) {
            if (!canplay || !video) {
                return false;
            }
            if (dir && typeof dir === "string") {
                if (dir === "forward") {
                    video.currentTime += 10;
                    this.onForwarded();
                } else if (dir === "rewind") {
                    video.currentTime -= 10;
                    this.onRewinded();
                }
            }
        }
    }

    onRewinded = () => {
        const instance = this.instance;
        if (instance.rewinded.current) {
            instance.rewinded.current.style.opacity = 1;
            setTimeout(() => {
                instance.rewinded.current.style.opacity = 0;
            }, 300)
        }
    }
    onForwarded = () => {
        const instance = this.instance;
        if (instance.forwarded.current) {
            instance.forwarded.current.style.opacity = 1;
            setTimeout(() => {
                instance.forwarded.current.style.opacity = 0;
            }, 300)
        }
    }

    volumeIncrease = () => {
        const instance = this.instance;
        const video = instance.video.current;
        const muted = instance.video.current.muted;
        if (instance) {
            if (video && muted)
                video.muted = false;
            video.volume = Math.min(1, video.volume + .1);
            instance.setState({volume: Math.min(1, video.volume + .1)});
        }
        // _this.volume = Math.min(1, _this.video.current.volume + .1);
    }

    volumeDecrease = (value) => {
        const instance = this.instance;
        const video = instance.video.current;
        const muted = instance.video.current.muted;
        if (value) {
            video.volume = value;
            instance.setState({volume: Math.max(0, value)});
            return;
        }
        if (video && muted)
            video.muted = false;
        video.volume = Math.max(0, video.volume - .1);
        instance.setState({volume: Math.max(0, video.volume - .1)});
        // _this.volume = Math.max(0, _this.video.current.volume - .1);
    }

    toggleVolume = () => {
        const instance = this.instance;
        const video = instance.video.current;
        const muted = instance.video.current.muted;
        if (video) {
            video.muted = !video.muted;
        }
        localStorage.setItem("splayer-volume", '0');
        localStorage.getItem("splayer-volume")
    }

    toggleFullscreen = () => {
        const _this = this;
        if (!_this._checkFullscreen()) {
            _this.enterFullScreen();
        } else {
            _this.exitFullscreen();
        }
    }

    enterFullScreen = () => {
        const instance = this.instance;
        const wrapper = instance.wrapper.current;
        const video = instance.video.current;
        const _this = this;
        if (!_this._checkFullscreen()) {
            if (_this._getPlatformType() === "iPhone") {
                video.setAttribute("playsinline", "false");
                setTimeout(() => {
                    _this._pauseVideo();
                    _this._playVideo();
                }, 30);
            } else {
                if (wrapper && wrapper.requestFullscreen) {
                    wrapper.requestFullscreen();
                } else if (wrapper && wrapper.webkitEnterFullscreen) {
                    wrapper.webkitEnterFullscreen()
                } else if (wrapper && wrapper.mozRequestFullscreen) {
                    wrapper.mozRequestFullscreen();
                } else if (wrapper && wrapper.msRequestFullscreen) {
                    wrapper.msRequestFullscreen();
                } else {
                    console.warn("Your browser doesn't support fullscreen!");
                }
            }
        }
    }

    exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }


    /**
     * Controls ыг toggle болгох гэж хичээсэн ч нэг л бодсоноор ажиллахгүй юм.
     * Маргааш засая даа.
     * @private
     */
    _toggleControls = () => {
        const instance = this.instance;
        if (this.controlsShown) {
            this._hideControls();
        } else {
            this._showControls();
        }
    }

    _showControls = () => {
        const instance = this.instance;
        const controls = instance.controls.current;
        const backButton = instance.backButton.current;
        const title = instance.title.current;
        if (controls.classList.contains("hidden")) {
            controls.classList.remove("hidden");
            if (backButton)
                backButton.style.opacity = 1;
            if (title)
                title.style.opacity = 1;
            // this._showMobileControls();
            this._startControlsTimeout();
        }
        this.controlsShown = true;
    }

    _hideControls = () => {
        const instance = this.instance;
        const controls = instance.controls.current;
        const backButton = instance.backButton.current;
        const title = instance.title.current;
        if (!controls?.classList.contains("hidden")) {
            if (this.overrideTimeout) {
                console.error("timeout is overridden");
                return;
            } else {
                controls?.classList.add("hidden");
                if (backButton)
                    backButton.style.opacity = 0;
                if (title)
                    title.style.opacity = 0;
                this._hideMobileControls();
                this._clearControlsTimeout();
            }
        }
        this.controlsShown = false;
    }

    _hideMobileControls = () => {
        const instance = this.instance;
        const forward = instance.mobileForward.current;
        const play = instance.mobilePlay.current;
        const rewind = instance.mobileRewind.current;
        if (forward) {
            forward.classList.add("hidden");
            forward.setAttribute("disabled", "");
        }
        if (play) {
            play.classList.add("hidden");
            play.setAttribute("disabled", "");
        }
        if (rewind) {
            rewind.classList.add("hidden");
            rewind.setAttribute("disabled", "");
        }
    }

    _showMobileControls = () => {
        const instance = this.instance;
        const forward = instance.mobileForward.current;
        const play = instance.mobilePlay.current;
        const rewind = instance.mobileRewind.current;
        if (forward) {
            forward.classList.remove("hidden");
            forward.removeAttribute("disabled");
        }
        if (play) {
            play.classList.remove("hidden");
            play.removeAttribute("disabled");
        }
        if (rewind) {
            rewind.classList.remove("hidden");
            rewind.removeAttribute("disabled");
        }
    }

    _startControlsTimeout = () => {
        const instance = this.instance;
        const _this = this;
        if (!this.overrideTimeout) {
            instance.controlsTimeout = setTimeout(() => {
                _this._hideControls();
                _this._clearControlsTimeout();
            }, 3500)
        }
    }

    _overrideControlsTimeout = async () => {
        const instance = this.instance;
        if (instance.controlsTimeout) {
            clearTimeout(instance.controlsTimeout);
        }
        this._showControls();
        this.overrideTimeout = true;
    }

    _revokeOverrideControlsTimeout = () => {
        console.error("RevokeOverrideControlsTimeout");
        const instance = this.instance;
        this.overrideTimeout = false;
        this._startControlsTimeout();
    }

    _clearControlsTimeout = () => {
        const instance = this.instance;
        if (instance.controlsTimeout) {
            clearTimeout(instance.controlsTimeout);
        }
    }

    _playVideo = () => {
        const instance = this.instance;
        const video = instance.video.current;
        if (video) {
            instance.play = video.play();
        }
    }

    _pauseVideo = () => {
        const instance = this.instance;
        const video = instance.video.current;
        if (instance.play !== undefined) {
            instance.play.then(_ => {
                video.pause()
            })
                .catch(error => {
                    console.warn(error);
                })
        }
    }

    _formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);

        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = seconds.toString().padStart(2, "0");

        return hours ?
            `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
            :
            `${formattedMinutes}:${formattedSeconds}`;
    }

    _changeSeekbarProgress = () => {
        const instance = this.instance;
        const video = instance.video.current;
        const seekbarRect = instance.seekbar.current.getBoundingClientRect();
        const progressPos = video.currentTime / video.duration;
        const newLeft = progressPos * seekbarRect.width;
        instance.thumb.current.style.left = `${newLeft}px`;
        instance.playingProgress.current.style.width = `${newLeft + 5}px`;
    }

    _changeBufferedProgress = () => {
        const instance = this.instance;
        const _this = this;
        const seekbarRect = instance.seekbar.current.getBoundingClientRect();
        const video = instance.video.current;
        if (instance.video.current.buffered.length > 0) {
            const start = video.buffered.start(0);
            const end = video.buffered.end(0);
            const bufferedWidth = (end - start) / video.duration * seekbarRect.width;
            instance.bufferedProgress.current.style.width = `${bufferedWidth}px`;
        }
    }

    _checkFullscreen = () => {
        return document.fullscreenElement || document.mozFullScreen || document.webkitFullScreen;
    }


    // settings methods
    _settingsInitStyles = (e) => {
        const instance = this.instance;
        const controlsRect = instance.controls.current.getBoundingClientRect();
        const settings = instance.settings.current;
        const body = instance.settingsBody.current;
        const bodyRect = body.getBoundingClientRect();
        const main = instance.settings_mainSection.current;
        const mainRect = main.getBoundingClientRect();
        const quality = instance.settings_qualitySection.current;
        const speed = instance.settings_speedSection.current;

        settings.style.bottom = `${controlsRect.height}px`;
        body.style.width = `${mainRect.width}px`;
        body.style.height = `${mainRect.height}px`;
        quality.style.right = `${mainRect.width}px`;
        speed.style.right = `${mainRect.width}px`;
    }

    _showSettings = () => {
        const instance = this.instance;
        const settingsButton = instance.settingsButton.current;
        const settings = instance.settings.current;
        instance.settingsShown = true;
        settingsButton.style.transform = "rotate(50deg)";
        settings.style.opacity = "1";
        settings.style.pointerEvents = "all";
        this._overrideControlsTimeout();

    }

    _hideSettings = () => {
        const instance = this.instance;
        const settingsButton = instance.settingsButton.current;
        const settings = instance.settings.current;
        instance.settingsShown = false;
        settingsButton.style.transform = "rotate(0deg)";
        settings.style.opacity = "0";
        settings.style.pointerEvents = "none";
        this._resetSettingsSection();
        this._revokeOverrideControlsTimeout();
    }

    _handleClickOnSpeedItem  = (speed) => {
        const instance = this.instance;
        const spd = document.querySelector(".spd-label");
        instance.video.current.playbackRate = speed.value;
        spd.innerText = speed.label;
        this._resetSettingsSection();
    }

    _handleClickOnQualityItem = (quality) => {
        const instance = this.instance;
        instance.setState({currentQuality: quality})
        instance.video.current.load();
        instance.video.current.currentTime = instance.currentTime;
        this._playVideo();
        this._resetSettingsSection();
    }

    _changeSettingsSection = (section) => {
        const instance = this.instance;
        const main = instance.settings_mainSection.current
        const body = instance.settingsBody.current
        if (section) {
            main.style.right = `-${section.getBoundingClientRect().width * 2}px`;
            section.style.right = "0";
            body.style.width = `${section.getBoundingClientRect().width}px`;
            body.style.height = `${section.getBoundingClientRect().height}px`;
        }
    }

    _resetSettingsSection = () => {
        const instance = this.instance;
        const body = instance.settingsBody.current
        const sections = document.querySelectorAll(".splayer_settings-section");
        const mainSection = document.getElementById("main-section");
        const mainRect = document.getElementById("main-section").getBoundingClientRect();
        for (const section of sections) {
            body.style.width = `${mainRect.width}px`;
            body.style.height = `${mainRect.height}px`;
            if (section !== mainSection)
                section.style.right = `${mainRect.width}px`;
            else
                mainSection.style.right = "0";
        }
    }


    onClickOnEpisodes = () => {
        const instance = this.instance;
        if (instance && instance.props && instance.props.onClickEpisodes)
            instance.props.onClickEpisodes();
    }


    onClickNextEpisode = () => {
        const instance = this.instance;
        if (instance && instance.props && instance.props.onClickNextEpisode)
            instance.props.onClickNextEpisode();
    }


    /**
     * @summary
     * end of Methods
     */


}

export default ReactSplayerEvents;
